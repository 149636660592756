<template>
    <PageTable endpoint="contacts" formclass="Contact/form" hideNew hideDelete :aditionalColumns="headers">
        <template>
            <h1>Contatos</h1>
            <v-divider class="mb-4" />
        </template>
        <template v-slot:extra-column="item">
            <v-chip class="ma-0" outlined color="success" v-if="item.type == 'CREATE_EXPERT'" x-small> {{ $t("Solicitação de cadatro para especialista") }} </v-chip>
            {{ $moment(item.createdAt).format("DD/MM/YY HH:mm") }}
        </template>
    </PageTable>
</template>

<script>
import PageTable from "@/components/core/PageTable";
export default {
    components: {
        PageTable,
    },
    data() {
        return {
            headers: [
                { text: this.$t("Data"), value: "extra-column", align: "right", sortable: true },
                { text: this.$t("Telefone"), value: "phone", align: "right", sortable: false },
                { text: this.$t("Email"), value: "email", align: "right", sortable: false },
                { text: this.$t("Nome"), value: "name", align: "right", sortable: true },
            ],
        };
    },
};
</script>